import { useState } from "react"
import incognite from '../../../assets/incognito.png'
export const UsernameAndPasswordInput = ({
  username, password, handleChange, handleBlur, touched, errors, showPasswordMode = false, virtualKeyword, valueKeyBoardVirtual,
  selectActive, setSelectActive, selectItem, setSelectItem, opciones
}) => {
  
  const [showPassword, setShowPassword] = useState(false)

  return (
    <>
      
      {/* <UsernameAndPasswordInputSelector 
                  
        selectActive={selectActive} 
        setSelectActive={setSelectActive}
        selectItem={selectItem}
        setSelectItem={setSelectItem}
        opciones={opciones}
      
      /> */}
      <div className="mt-[8px] mb-[38px] flex flex-col">
        <span className="text-[18px] pb-[4px] text-[#333] arial">Identificación de usuario</span>
        <input 
          type="text" 
          name="username"
          onBlur={handleBlur}
          onChange={handleChange}
          value={username}
          inputMode="text"
          required
          className="outline-none border-[1px] w-[209px] border-[#ccc]"
        />
        <div>
          <input className="mt-[2px] mr-[6px]" type="checkbox" name="saveAs" id="saveAs" />
          <label className="text-[13px] text-[#303332]" htmlFor="saveAs">Guardar esta Identificación de usuario</label>
        </div>
        <div>
          <img src={incognite} alt="" />
        </div>
      </div>
      {/* 
        {
          touched.username && errors.username && (
            <p>{errors.username}</p>
          )
        } 
      */}
  
      <div className="flex flex-col mb-[24px]">
        <span className="text-[18px] pb-[4px] text-[#333]">Contraseña</span>
        <input 
          name="password"
          className="outline-none border-[1px] w-[209px] border-[#ccc] transition-all "
          onBlur={handleBlur}
          onChange={handleChange}
          // value={valueKeyBoardVirtual}
          value={password}
          disabled={username.length == 0}
          type={(showPassword === false || showPasswordMode === false) == true ? 'password' :  'text' } 
          // PUEDE EL INPUT MODE
          // inputMode="numeric"
          required
        />
      </div>
      {/* 
        {
          touched.password && errors.password && (
            <p>{errors.password}</p>
          )
        } 
      */}
      
      {/* <div>
        <input 
          onClick={() => setShowPassword(!showPassword)}
          type="checkbox" 
          id="passwordView" 
        />
        <label htmlFor="passwordView">{(showPassword === false || showPasswordMode === false) == true ? 'Ver' :  'Ocultar'}</label>
      </div> */}
      
    </>
  )
}
