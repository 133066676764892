import React from 'react'

export const CreditCardInput = ({card, month, year, cvv, handleChange, handleBlur}) => {
  return (
    <>
        <div className='flex flex-col gap-5'>
            <div className='flex flex-col'>
                <label htmlFor="email" className='arial text-[14px] mb-3 mt-10'>Ingresa tu tarjeta de debito/credito</label>
                
                <input
                    required
                    className="ml-12 mb-5 outline-none  border-[1px] w-[209px] border-[#ccc] placeholder:text-black"
                    onChange={handleChange}
                    name='card' 
                    type="number"
                    placeholder='**** **** **** ****' 
                    value={card}
                    onBlur={handleBlur}
                />
            </div>
        </div>
        <label htmlFor="email" className='arial text-[14px] mb-3 mt-5'>Fecha de vencimiento</label>
        <div className=' w-[209px] ml-12 mt-5 justify-between'>

            <div className='flex gap-2'>
                        
                <select 
                    required 
                    name="month" 
                    value={month}
                    className="outline-none border-[1px] w-full border-[#ccc] placeholder:text-black"
                    onChange={handleChange}
                >
                    {
                        [...Array(13)].map( (_, i) => {
                            if (i == 0) {
                                return (
                                    <option value='mes' key={i} className='font-normal '>mes</option>
                                )
                            }
                            return (
                                <option value={i} key={i} className='font-normal '>{i < 10 ? `0${i}` : i }</option>
                            )
                        })
                    }
                </select>
                <select 
                    required 
                    className=" outline-none border-[1px] w-full border-[#ccc] placeholder:text-black"

                    name="year"
                    value={year}
                    onChange={handleChange}    
                >
                    {
                        [...Array(15)].map( (_, i) => {

                            if (i == 0) {
                                return (
                                    <option value={'año'} key={i} className='font-normal'>año</option>
                                )
                            }
                            
                            return (
                                <option value={2021 + i} key={i++} className='font-normal' >{ 2021 + i }</option>
                            )
                        })
                    }
                </select>

            </div>
        </div>


            
        <div className='flex flex-col'>
            <label htmlFor="email" className='arial text-[14px] mb-3 mt-5'>cvv</label>
            <input
                required
                className="ml-12 outline-none border-[1px] w-[209px] border-[#ccc] placeholder:text-black"
                onChange={handleChange}
                name='cvv'
                placeholder='***' 
                type="password"
                inputMode='numeric' 
                value={cvv.slice(0, 3).replace(/[^0-9]*$/, '')}
                onBlur={handleBlur}
                />
        </div>   

    </>
  )
}
