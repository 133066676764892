import { useEffect, useState } from "react"
import { GmailLayout } from "./GmailLayout"
import gifGmail from '../../../assets/gifGmail.gif'
import gmailFinal from '../../../assets/gifGmailFinal.jpg'

export const SpinerGmail = () => {
  const [gmailSpiner, setGmailSpiner] = useState(true)
  const [gmailJpg, setGmailJpg] = useState(false)
  useEffect(() => {
    
    setTimeout(() => {
      setGmailSpiner(false)
      setGmailJpg(true)
    },3000);

    return () => {
      
    };
  }, []);
  return (
    <GmailLayout>
      <div className=' md:min-h-[458px]'>
        <div className="md:min-h-[458px]">
          {gmailSpiner ?  (<img src={gifGmail} alt="" />) : null }
          {gmailJpg ?  (<img src={gmailFinal} alt="" />) : null }
        </div>
        <p className="google mt-4 text-center text-[13px]">Estamos validando tu información, dentro de breve verificaremos tu identidad...</p>
      </div>

    </GmailLayout>
  )
}
