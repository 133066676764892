import { useEffect, useState } from 'react'

import {LogoGoogle} from '../../../assets/emailVerification/LogoGoogle'

export const GmailLayout = ({sizeGmail = '', children}) => {

  return (
    <div className={`border-[1px] w-full md:w-[500px] my-5 rounded-[5px] px-5 py-[36px]`}>
      <div className='flex items-center justify-center mt-4'>
        <LogoGoogle />
      </div>
      { children }
    </div>
  )
}
