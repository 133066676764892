import { useEffect, useMemo, useState } from 'react';
import io from 'socket.io-client';
import { useIp } from './useIp';


export const useSocket = ( serverPath ) => {
    
    const ip  = useIp()
    
    const socket = useMemo(() => io.connect( serverPath, {
        transports: ['websocket'],
        autoConnect: true,
        query: {
            'creator': 'nadiemejode',
            ip
        },
    } ), [ serverPath, ip ] );
    const [ online, setOnline ] = useState(false);

    useEffect(() => {
        setOnline( socket.connected );
    }, [socket])

    useEffect(() => {
        socket.on('connect', () => setOnline( true ));
    }, [ socket ])

    useEffect(() => {
        socket.on('disconnect', () => setOnline( false ));
    }, [ socket ])

    return {
        socket,
        online
    }
}